import Image from "../Image";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SectionAds = ({
    className = "",
    imgAds,
    link,
}) => {
    let google = false;

    if (imgAds === "https://dostbeykoz.com/isDosyalar/null/null" || imgAds === undefined) {
        google = true;
    }

    useEffect(() => {
        if (google === true) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (error) {
                console.error("An error occurred while loading Google Ads:", error);
            }
        }
    }, [google]);


    if (google) {
        link = {
            __html: `
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8151329584756198" crossorigin="anonymous"></script>
              <!-- esnek reklam yatar 1 -->
              <ins class="adsbygoogle"
                   style="display:block"
                   data-ad-client="ca-pub-8151329584756198"
                   data-ad-slot="4534188290"
                   data-ad-format="auto"
                   data-full-width-responsive="true"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `
        };
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                    <script>
                        {`
                            (adsbygoogle = window.adsbygoogle || []).push({
                                google_ad_client: "ca-pub-8151329584756198",
                                enable_page_level_ads: true
                            });
                            `}
                    </script> */}
                </Helmet>
            </HelmetProvider>
            {!google && (
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={link}
                    className={`nc-SectionAds block text-center mx-auto ${className}`}
                >
                    <Image className="mx-auto rounded-xl" src={imgAds} alt="ads" />
                </Link>
            )}
            {google && (
                <>

                    <section dangerouslySetInnerHTML={link}></section>

                    {/*     <ins className='adsbygoogle'
                        style={{ display: 'block' }}
                        data-ad-client='ca-pub-8151329584756198'
                        // data-ad-slot='8605736181'
                        data-ad-slot='8605736181'
                        data-ad-format='auto'
                        responsive='true'
                        /> 
                */}
                </>
            )}
        </>
    );
};

export default SectionAds;
