import { useEffect, useState, useMemo, useCallback } from "react";
import LazyLoad from "react-lazyload";
import SectionAds from "../../components/Sections/SectionAds";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import { fetchAPI } from "../../hooks/fetchApı";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import SectionSliderPosts from "../../components/Sections/SectionSliderPosts";
import SectionLargeSlider from "../../app/(home)/SectionLargeSlider";
import SectionMagazine2 from "../../components/Sections/SectionMagazine2";
import Loading from "../../components/Loading";
import SectionGridAuthorBox from "../../components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridPosts from "./SectionGridPosts";
import CurrencyComponent from "./CurrencyComponent";
import { decodeHTMLJson } from "../../utils/decodeHTML";
import BreakingNewsComponent from "./BreakingNewsComponent";
import { Link } from "react-router-dom";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
    const [homeData, setHomeData] = useState({
        firstArea: [],
        headlineNews: [],
        allCategoryNews: [],
        videoGallery: [],
        economy: {},
        authors: [],
        ads: [],
        breakingNews: []
    });
    const [loading, setLoading] = useState(true);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    const transformItem = (item, path = "isDosyalar/") => ({
        ...item,
        featuredImage: `https://dostbeykoz.com/${path}${item.resim}`,
        href: `/${item.href}`,
    });

    const fetchData = useCallback(async () => {
        try {
            const homeApiData = await fetchAPI('home', createFormData, sendRequest);

            const firstAreaData = homeApiData.firstArea.map(item => transformItem(item));
            const headlineNewsData = homeApiData.headlineNews.map(item => transformItem(item));
            const videoGalleryData = homeApiData.videoGallery.map(item => transformItem(item));
            const breakingNewsData = homeApiData.breakingNews.map(item => ({
                ...item, href: `/${item.href}`
            }));

            const authorsData = await fetchAPI('authors', createFormData, sendRequest);
            const transformedAuthors = authorsData.map(item => ({
                ...item,
                avatar: `https://dostbeykoz.com/${item.avatar.replace(".webp", ".jpg")}`,
                href: `/yazarlar/detay/${item.href}`
            }));

            const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 27);
            const transformedAds = adsData.map(item => ({
                ...item,
                imgAds: `https://dostbeykoz.com/isDosyalar/${item.yol}/${item.url}`
            }));

            const services = await fetchAPI(
                'getServices',
                createFormData,
                sendRequest,
                null,
                null,
                "'gasoline','currency','gold','cripto'"
            );

            setHomeData({
                firstArea: firstAreaData,
                headlineNews: headlineNewsData,
                allCategoryNews: homeApiData.categoryNews,
                videoGallery: videoGalleryData,
                breakingNews: breakingNewsData,
                authors: transformedAuthors,
                ads: transformedAds,
                economy: services,
            });

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data: ", error);
            setLoading(false);
        }
    }, [createFormData, sendRequest]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const economy = useMemo(() => {
        const mapServiceData = (service) => {
            if (!service) {
                return { updatedDate: null, data: null };
            }

            let parsedData;
            try {
                parsedData = decodeHTMLJson(service?.gelen_veri);
            } catch (error) {
                console.error("Error parsing service data:", error);
                parsedData = { data: null };
            }

            return {
                updatedDate: service?.ekleme_tarihi,
                data: parsedData.data,
            };
        };

        return {
            currencyData: mapServiceData(homeData.economy[0]),
            goldData: mapServiceData(homeData.economy[1]),
            cryptoData: mapServiceData(homeData.economy[2]),
            gasolineData: mapServiceData(homeData.economy[3]),
        };
    }, [homeData.economy]);

    const renderSections = (ads) => {
        return Object.keys(homeData.allCategoryNews).map((category, key) => {
            const isDarkBackground = key % 2 === 1;
            const sectionBg = isDarkBackground ? <BackgroundSection width="full" type="dark" /> : null;

            return (
                <>
                    <div className="container relative my-4" key={category}>
                        {sectionBg}
                        <LazyLoad height={200} offset={100} once>
                            <SectionMagazine2
                                className="py-8 lg:py-12"
                                heading={category}
                                textClass={isDarkBackground ? "text-neutral-100" : "text-neutral-900"}
                                posts={homeData.allCategoryNews[category]}
                                showHeader={true}
                            />
                        </LazyLoad>
                        <div className="flex pb-4 justify-center items-center">
                            <Link to={`/kategori/${homeData.allCategoryNews[category][0].categoryLink}`}>
                                <ButtonPrimary className="w-96">Devamını Gör</ButtonPrimary>
                            </Link>
                        </div>

                    </div>

                    {ads[key] && (
                        <div className="container">
                            <SectionAds link={ads[key].link} imgAds={ads[key].imgAds} title={ads[key].baslik} />
                        </div>
                    )}
                </>
            );
        });
    };

    return (
        <>
            {loading ? <Loading /> : (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>Dost Beykoz | BEYKOZ HABER SİTESİ</title>
                            <link rel="icon" href="/images/logo-small.png" data-rh="true" />
                        </Helmet>
                    </HelmetProvider>
                    <div className="nc-PageHome relative">
                        <div className="relative">
                            {homeData.ads[0] && (
                                <div className="container">
                                    <SectionAds {...homeData.ads[0]} />
                                </div>
                            )}

                            <section className="container flex flex-col gap-y-2.5">
                                {homeData.breakingNews.length > 0 && (
                                    <LazyLoad height={200} offset={100} once>
                                        <BreakingNewsComponent data={homeData.breakingNews} />
                                    </LazyLoad>
                                )}
                            </section>

                            <section className="container relative my-4">
                                <BackgroundSection />
                                <LazyLoad height={200} offset={100} once>
                                    <SectionSliderPosts posts={homeData.firstArea} card="Card11" />
                                </LazyLoad>
                            </section>

                            {homeData.ads[1] && (
                                <div className="container">
                                    <SectionAds {...homeData.ads[1]} />
                                </div>
                            )}

                            <section className="dark bg-neutral-900 text-neutral-100 rounded-lg py-0 md:py-20">
                                <div className="relative container">
                                    <LazyLoad height={200} offset={100} once>
                                        <SectionLargeSlider
                                            className="pt-10 pb-16"
                                            posts={homeData.headlineNews}
                                        />
                                    </LazyLoad>
                                </div>
                            </section>

                            <section className="container mt-4">
                                <LazyLoad height={200} offset={100} once>
                                    <CurrencyComponent data={economy} />
                                </LazyLoad>
                            </section>

                            {homeData.ads[2] && (
                                <div className="container my-12">
                                    <SectionAds {...homeData.ads[2]} />
                                </div>
                            )}

                            <section className="container mt-4 relative">
                                <LazyLoad height={200} offset={100} once>
                                    <SectionGridAuthorBox authors={homeData.authors} />
                                </LazyLoad>
                            </section>

                            {renderSections(homeData.ads)}

                            <section className="dark bg-neutral-900 text-neutral-100 rounded-lg">
                                <div className="relative container">
                                    <LazyLoad height={200} offset={100} once>
                                        <SectionGridPosts
                                            className="py-16 lg:py-28"
                                            headingIsCenter
                                            heading="Video Galeri"
                                            buttonLink="/video-galeri"
                                            posts={homeData.videoGallery}
                                            gridClass="md:grid-cols-2 lg:grid-cols-3"
                                        />
                                    </LazyLoad>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Home;
